import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import {
    AddModule,
    BasicContentEditor,
    CloseButton,
    combineStrings,
    docsSelector,
    EditContent,
    EditTitle,
    IApiModuleVersion,
    IApplicationStore,
    IWorkflowFormField,
    IWorkflowType,
    modulesSelector,
    ModuleVersionUtils,
    OutlineUtils,
    RemoveModule,
    WorkflowUtils,
    YonderButton,
} from '@yonder-mind/ui-core';
import { IFormValues } from '../../../../interfaces';
import { Form, FormFields } from '../../../FormStructure';
import { ICrSelection, ModuleVersionContentHighlightSelector } from '../../../ModuleVersion';
import { WorkflowCRProposalScreen } from '../../../../config';
import { useWorkflow } from '../../../../context';
import { WarningTwoTone } from '@material-ui/icons';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';

interface IProps {
    type: IWorkflowType;
    fields: IWorkflowFormField[];
    moduleVersion: IApiModuleVersion;
    onClose: () => any;
    onSubmit: (values: IFormValues) => any;
}

interface IFormButton {
    text: string;
    action: () => any;
}

interface IFormButtons {
    primary: IFormButton;
    secondary: IFormButton;
}

export const ChangeRequestForm: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));
    const moduleOid = props.moduleVersion.moduleOid;
    const module = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextVersionOid(state, moduleOid, contextVersion?.oid)
    );

    const useEditor =
        (props.type === IWorkflowType.EDIT_CONTENT || props.type === IWorkflowType.ADD_MODULE) &&
        props.fields.find((field) => field.id === 'PROPOSAL_SCREEN')?.defaultValue === WorkflowCRProposalScreen.EDITOR;

    const { actions, allRevisions } = useWorkflow('revision');

    React.useEffect(() => {
        if (!allRevisions) {
            actions.requestAllRevisions();
        }
    }, [allRevisions]);

    const moduleExternalId = module && module.externalKey.externalId;

    const documentExternalId = contextVersion.contextExternalKey && contextVersion.contextExternalKey.externalId;

    // TODO: With this info, the UI is controlling the flow of the Workflow - it leads the process into a invalid/wrong state, when UI is not refreshed and that's just bad practice and unacceptable
    //  This decision is BUSINESS LOGIC and belong into backend
    const contextVersionInRevision = allRevisions.find(
        (revision) =>
            revision.variables.CONTEXT_ID === contextVersion.contextOid && !revision.variables.IS_TEMPORARY_REVISION
    );

    const docSubTypeRoleSuffix = props.fields.find((field) => field.id === 'DOC_SUB_TYPE_ROLE_SUFFIX') !== undefined;
    const injectedValues = {
        CHANGE_REQUEST_MODULE_VERSION_ID: props.moduleVersion.oid,
        CHANGE_REQUEST_MODULE_ID: props.moduleVersion.moduleOid,
        CHANGE_REQUEST_CONTEXT_VERSION_TO_EDIT_ID: contextVersionInRevision
            ? WorkflowUtils.getContextVersionOid(contextVersionInRevision.variables)
            : contextVersion.oid,
        CONTEXT_VERSION_ID: contextVersion.oid,
        CONTEXT_ID: contextVersion.contextOid,
        CHANGE_REQUEST_CODE: contextVersion.code,
        MODULE_EXTERNAL_ID: moduleExternalId,
        DOCUMENT_EXTERNAL_ID: documentExternalId,
        DOCUMENT_ID: contextVersion.oid,
        ...(docSubTypeRoleSuffix && { DOC_SUB_TYPE_ROLE_SUFFIX: contextVersion.subType }),
    };

    const [hasTextProposal, setHasTextProposal] = React.useState(false);
    const [page, setPage] = React.useState(
        props.type === IWorkflowType.EDIT_CONTENT || props.type === IWorkflowType.ADD_MODULE ? 0 : 1
    );

    const cancel = props.onClose;
    const back = () => setPage(page - 1);
    const next = () => setPage(page + 1);

    const getButtons = (abort: () => any, submit: () => any): IFormButtons => {
        if (props.type !== IWorkflowType.EDIT_CONTENT && props.type !== IWorkflowType.ADD_MODULE) {
            return {
                primary: {
                    text: t('form.actions.submit'),
                    action: submit,
                },
                secondary: {
                    text: t('form.actions.cancel'),
                    action: abort,
                },
            };
        }

        switch (page) {
            case 0:
                return {
                    primary: {
                        text: t('form.actions.next'),
                        action: next,
                    },
                    secondary: {
                        text: t('form.actions.cancel'),
                        action: abort,
                    },
                };
            case 1:
                return {
                    primary: {
                        text: t('form.actions.submit'),
                        action: submit,
                    },
                    secondary: {
                        text: t('form.actions.back'),
                        action: back,
                    },
                };
            default:
                return {
                    primary: {
                        text: 'undefined',
                        action: () => null,
                    },
                    secondary: {
                        text: 'undefined',
                        action: () => null,
                    },
                };
        }
    };

    const moduleVersionNumbering = ModuleVersionUtils.getModuleVersionNumbering(contextVersion, props.moduleVersion);
    const moduleVersionTitle =
        contextVersion.moduleVersionOutlineInfo[props.moduleVersion.moduleOid]?.moduleVersionTitle ??
        t('reader.moduleVersion.notFound');
    const getWorkflowIcon = () => {
        switch (props.type) {
            case IWorkflowType.EDIT_CONTENT:
                return <EditContent />;
            case IWorkflowType.EDIT_TITLE:
                return <EditTitle />;
            case IWorkflowType.ADD_MODULE:
                return <AddModule />;
            case IWorkflowType.DELETE_MODULE:
                return <RemoveModule />;
        }
    };

    const getInitialProposalText = () => {
        switch (props.type) {
            case IWorkflowType.EDIT_CONTENT:
                return props.moduleVersion.content;
            case IWorkflowType.EDIT_TITLE:
                return props.moduleVersion.content;
            case IWorkflowType.ADD_MODULE:
                return '';
            case IWorkflowType.DELETE_MODULE:
                return props.moduleVersion.content;
            default:
                return props.moduleVersion.content;
        }
    };

    const getFormTitle = () => {
        switch (props.type) {
            case IWorkflowType.EDIT_CONTENT:
                return t('workflow.changeRequest.create.title.editContent');
            case IWorkflowType.EDIT_TITLE:
                return t('workflow.changeRequest.create.title.editTitle');
            case IWorkflowType.ADD_MODULE:
                return t('workflow.changeRequest.create.title.addModule');
            case IWorkflowType.DELETE_MODULE:
                return t('workflow.changeRequest.create.title.deleteModule');
        }
    };

    const [editorContent, setEditorContent] = React.useState('');

    React.useEffect(() => {
        if (props.moduleVersion) {
            setEditorContent(getInitialProposalText());
        }
    }, [props.moduleVersion]);

    const getWarningMessage = () => {
        if (props.type === IWorkflowType.DELETE_MODULE) {
            const moduleInOutline = OutlineUtils.findModuleInOutline(contextVersion.outline, moduleOid);
            if (moduleInOutline.children.length > 0) {
                return (
                    <div className="warning-message-wrapper">
                        <div className="warning-message-wrapper--info">
                            <WarningTwoTone />
                            <p>{t(`workflow.changeRequest.warnings.deleteParentModule`)}</p>
                        </div>
                    </div>
                );
            }
            return null;
        }
        return null;
    };

    return (
        <Dialog
            open={true}
            maxWidth="xl"
            fullWidth={true}
            className="change-request--form"
            role="modal"
            data-testid="modal"
        >
            <Form
                fields={props.fields}
                onSubmit={props.onSubmit}
                injectedValues={injectedValues}
                hiddenFields={[
                    ...[
                        'CONTEXT_VERSION_ID',
                        'CONTEXT_ID',
                        'CHANGE_REQUEST_CONTEXT_VERSION_TO_EDIT_ID',
                        'CHANGE_REQUEST_MODULE_VERSION_ID',
                        'CHANGE_REQUEST_MODULE_ID',
                        'MARK_CONTENT',
                        'MARK_LOCATION',
                        'CHANGE_REQUEST_NUMBER',
                        'CHANGE_REQUEST_LABEL',
                        'DOCUMENT_ID',
                        'PROPOSAL_SCREEN',
                        'PROPOSAL_EDIT',
                    ],
                    ...(useEditor ? ['TEXT_PROPOSAL'] : []),
                ]}
            >
                {(form, submit) => {
                    const buttons = getButtons(cancel, submit.onClick);

                    const addRange = (selection: ICrSelection | undefined) => {
                        form.onChange('MARK_CONTENT', selection ? selection.textContent : '');
                        form.onChange('MARK_LOCATION', selection ? selection.location : '');
                    };

                    const hasRange = form.values.MARK_LOCATION && form.values.MARK_LOCATION !== '';

                    const addTextProposal = (proposal: string | undefined) => {
                        const regex = /\s/g;
                        const { content } = props.moduleVersion;
                        if (proposal === undefined || proposal.replace(regex, '') === content.replace(regex, '')) {
                            return;
                        } else {
                            if (editorContent.replace(regex, '') !== proposal.replace(regex, '')) {
                                setEditorContent(proposal);
                                form.onChange('TEXT_PROPOSAL', proposal);
                                setHasTextProposal(true);
                            }
                        }
                    };

                    const reset = () => {
                        if (useEditor) {
                            setHasTextProposal(false);
                            setEditorContent(getInitialProposalText());
                        } else {
                            addRange(undefined);
                        }
                    };

                    return (
                        <>
                            <DialogTitle disableTypography={true} data-testid="modalHeader">
                                <Grid container={true} alignItems="center">
                                    <Grid item={true} className="icon">
                                        {getWorkflowIcon()}
                                    </Grid>
                                    <Grid item={true} className="title">
                                        <Typography variant="h6">{getFormTitle()}</Typography>
                                        <Typography variant="subtitle2">
                                            {combineStrings([moduleVersionNumbering, moduleVersionTitle])}
                                        </Typography>
                                    </Grid>
                                    <div className="grow" />
                                    <CloseButton onClick={props.onClose} size="small" data-testid="modalCloseButton" />
                                </Grid>
                            </DialogTitle>
                            {getWarningMessage()}
                            <DialogContent data-testid="modalContent">
                                <SwipeableViews index={page} disabled={true} height="auto">
                                    {useEditor ? (
                                        <div className="module-version-editor">
                                            <BasicContentEditor
                                                document={contextVersion}
                                                moduleVersion={{
                                                    ...props.moduleVersion,
                                                    content: editorContent,
                                                }}
                                                onChange={addTextProposal}
                                            />
                                        </div>
                                    ) : (
                                        <div className="module-version-highlighter">
                                            <ModuleVersionContentHighlightSelector
                                                moduleVersion={props.moduleVersion}
                                                document={contextVersion}
                                                selection={{
                                                    location: form.values.MARK_LOCATION,
                                                    moduleVersionOid: props.moduleVersion.oid,
                                                    textContent: form.values.MARK_CONTENT,
                                                }}
                                                addRange={addRange}
                                            />
                                        </div>
                                    )}

                                    <FormFields {...form} />
                                </SwipeableViews>
                            </DialogContent>
                            <DialogActions data-testid="modalActions">
                                <div className="helper-text">
                                    {props.type === IWorkflowType.EDIT_CONTENT ||
                                    props.type === IWorkflowType.ADD_MODULE
                                        ? useEditor
                                            ? !hasTextProposal && t('workflow.changeRequest.create.helperText.edit')
                                            : !hasRange && t('workflow.changeRequest.create.helperText.mark')
                                        : null}
                                </div>

                                <div className="actions">
                                    {(props.type === IWorkflowType.EDIT_CONTENT ||
                                        props.type === IWorkflowType.ADD_MODULE) &&
                                    ((useEditor && hasTextProposal) || (!useEditor && hasRange)) &&
                                    page === 0 ? (
                                        <YonderButton variant="text" onClick={reset} data-testid="modalCancelButton">
                                            {t('form.actions.reset')}
                                        </YonderButton>
                                    ) : (
                                        <YonderButton
                                            variant="text"
                                            onClick={buttons.secondary.action}
                                            data-testid="modalCancelButton"
                                        >
                                            {buttons.secondary.text}
                                        </YonderButton>
                                    )}

                                    <YonderButton
                                        color="primary"
                                        variant="contained"
                                        onClick={buttons.primary.action}
                                        data-testid="modalConfirmationButton"
                                        disabled={
                                            (props.type === IWorkflowType.EDIT_CONTENT ||
                                                props.type === IWorkflowType.ADD_MODULE) &&
                                            ((useEditor && !hasTextProposal) || (!useEditor && !hasRange))
                                        }
                                    >
                                        {buttons.primary.text}
                                    </YonderButton>
                                </div>
                            </DialogActions>
                        </>
                    );
                }}
            </Form>
        </Dialog>
    );
};
