import React, { useEffect } from 'react';
import {
    authSelector,
    BasicContentEditor,
    FabButton,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    LockSource,
    modulesSelector,
    ModuleVersionContent,
    SnackBar,
    UrlSchemeUtils,
    useHistory,
    WorkflowUtils,
    YonderButton,
} from '@yonder-mind/ui-core';
import { useTranslation } from 'react-i18next';
import { useWorkflow } from '../../../../context';
import { Edit, WarningOutlined } from '@material-ui/icons';
import { importJobActions } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { IWebApplicationStore, IWorkflowCrInEditInfo } from '../../../../interfaces';
import { adjustEditorHeight, adjustEditorHeightOnResizeListenerHandler } from './AdjustEditorHeight';

interface IProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion;
    previousModuleVersion: IApiModuleVersion;
    currentPage: number;
    setIsEditing: (isEditing: boolean) => void;
    setSplitViewDisabled: (disabled: boolean) => void;
    diffingActive: boolean;
}

export const TextProposalView: React.FC<IProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    setIsEditing,
    setSplitViewDisabled,
    diffingActive,
}) => {
    const { t } = useTranslation();
    const { actions } = useWorkflow('process');
    const { actions: crActions } = useWorkflow('cr');
    const { pushUrl } = useHistory();
    const dispatch = useDispatch();

    const userInfo = useSelector(authSelector.userInfo);
    const { calculatedDiff } = useSelector((state: IWebApplicationStore) => ({
        calculatedDiff: state.workflow.changeRequest.calculatedDiff,
    }));
    const crModule = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextVersionOid(state, crModuleVersion?.moduleOid, document?.oid)
    );

    const importJobLockData = useSelector((store: IWebApplicationStore) => store.import.importJobLock);

    const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);

    const { editproposalrole } = changeRequest.variables;
    let canEditTextProposal = false;
    if (editproposalrole && !WorkflowUtils.isObsolete(changeRequest)) {
        const editproposalroles = editproposalrole.replace(/\s/g, '').split(',');
        for (const role of editproposalroles) {
            if (userInfo.roles.includes(role)) {
                canEditTextProposal = true;
                break;
            }
        }
    }

    const [isEditingTextProposal, setEditingTextProposal] = React.useState(false);
    const [crInEditLockInfo, setCRInEditLockInfo] = React.useState<IWorkflowCrInEditInfo | undefined>(undefined);

    const getProposalTextFromVariables = () => {
        if (changeRequest.variables.TEXT_PROPOSAL) {
            return UrlSchemeUtils.replaceUrlScheme(changeRequest.variables.TEXT_PROPOSAL, window.location.origin);
        }
        return '';
    };

    const getTextDiffFromVariables = () => {
        if (changeRequest.variables.TEXT_DIFF) {
            return UrlSchemeUtils.replaceUrlScheme(changeRequest.variables.TEXT_DIFF, window.location.origin);
        }

        return '';
    };

    const [textProposal, setTextProposal] = React.useState(getProposalTextFromVariables());
    const [textDiff, setTextDiff] = React.useState(getTextDiffFromVariables());

    useEffect(() => {
        adjustEditorHeight();
    }, [window.document.querySelector('.fr-toolbar')?.clientHeight]);

    useEffect(adjustEditorHeightOnResizeListenerHandler, []);

    React.useEffect(() => {
        const importJobLock = importJobLockData?.[contextVersionToEditOid];

        if (importJobLock && importJobLock.data) {
            const importJobLockUsername = importJobLock.data.username;
            const importJobLockSource = importJobLock.data.lockSource;
            if (
                importJobLockUsername !== userInfo.preferred_username ||
                (importJobLockUsername === userInfo.preferred_username && importJobLockSource === LockSource.ADMIN_UI)
            ) {
                setEditingTextProposal(false);
                setCRInEditLockInfo({ username: importJobLockUsername, lockSource: importJobLockSource });
            }
        }
    }, [importJobLockData]);

    React.useEffect(() => {
        setIsEditing(isEditingTextProposal);
    }, [isEditingTextProposal]);

    React.useEffect(() => {
        setTextProposal(getProposalTextFromVariables());
    }, [UrlSchemeUtils.replaceUrlScheme(changeRequest.variables.TEXT_PROPOSAL, window.location.origin), diffingActive]);

    React.useEffect(() => {
        if (isEditingTextProposal || textDiff !== '') {
            return;
        }
        crActions.calculatedDiffRequested(crModuleVersion.content, textProposal);
    }, [textProposal, isEditingTextProposal]);

    React.useEffect(() => {
        if (!calculatedDiff) {
            return;
        }

        setTextDiff(calculatedDiff);

        // When the CR is completed then we can't update the variables anymore
        if ('state' in changeRequest && changeRequest.state === 'COMPLETED') {
            return;
        }

        actions.updateProcessVariables(changeRequest.processInstanceId, {
            TEXT_DIFF: calculatedDiff,
        });
    }, [calculatedDiff]);

    const editTextProposal = () => {
        dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));

        setEditingTextProposal(true);
        setSplitViewDisabled(true);

        crActions.requestCRContent(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
        );
    };

    const closeTextProposalEditor = () => {
        UrlSchemeUtils.replaceUrlScheme(changeRequest.variables.TEXT_PROPOSAL, window.location.origin);
        setEditingTextProposal(false);
        setSplitViewDisabled(false);

        crActions.cancelEditCRContent(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
        );
    };
    const saveTextProposal = () => {
        setEditingTextProposal(false);
        setSplitViewDisabled(false);

        if (canEditTextProposal) {
            actions.updateProcessVariables(changeRequest.processInstanceId, { TEXT_PROPOSAL: textProposal });
            crActions.calculatedDiffRequested(crModuleVersion.content, textProposal);
        }

        crActions.cancelEditCRContent(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
        );
    };

    const isLegalCr = changeRequest?.variables?.TARGET_CONTEXT_VERSION_ID;
    const openDiffingForAutChange = () => {
        pushUrl(`/module-diffing/${isLegalCr}`, [
            { key: 'moduleVersionOid', value: changeRequest?.variables?.TARGET_MODULE_ID },
        ]);
    };

    return (
        <div className={`module-version module-version-item ${isLegalCr ? 'legal-cr' : ''}`}>
            {isLegalCr && (
                <div className="auto-cr-legal">
                    <div className="warning-message">
                        <WarningOutlined /> {t('filterAndSort.workflow.linkedLegalChanged.contentModified')}
                    </div>
                    <div onClick={openDiffingForAutChange} className="comparison-view-link">
                        {t('filterAndSort.workflow.linkedLegalChanged.linkTitle')}
                    </div>
                </div>
            )}
            {isEditingTextProposal ? (
                <>
                    <BasicContentEditor
                        document={document}
                        moduleVersion={{
                            ...crModuleVersion,
                            content: textProposal,
                        }}
                        onChange={setTextProposal}
                    />
                    <div className="content-editor__actions">
                        <YonderButton variant="text" onClick={closeTextProposalEditor}>
                            {t('form.actions.cancel')}
                        </YonderButton>
                        <div className="grow" />
                        <YonderButton variant="text" onClick={saveTextProposal}>
                            {t('form.actions.save')}
                        </YonderButton>
                    </div>
                </>
            ) : (
                <>
                    <ModuleVersionContent
                        document={document}
                        moduleVersion={{
                            ...crModuleVersion,
                            content: diffingActive ? textDiff : textProposal,
                        }}
                        links={crModule ? crModule.links : []}
                    />
                    <div className="module-version-item__view proposal">
                        {t('workflow.changeRequest.views.proposal')}
                    </div>
                    {canEditTextProposal && (
                        <FabButton size="small" onClick={editTextProposal}>
                            <Edit />
                        </FabButton>
                    )}
                </>
            )}
            {crInEditLockInfo && (
                <SnackBar
                    isOpen={true}
                    message={t('workflow.changeRequest.errors.jobLocked', crInEditLockInfo)}
                    variant="warning"
                    position="fixed"
                    onClose={() => setCRInEditLockInfo(undefined)}
                />
            )}
        </div>
    );
};
